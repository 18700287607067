$MD: 768px;

@mixin MQ($canvas) {
  @if $canvas==MD {
    @media screen and (max-width: $MD) {
      @content;
    }
  }
  @else if $canvas==LG {
    @media screen and (min-width: $MD) {
      @content;
    }
  }
}

.form {
  @include MQ(MD) {
     
  }
  @include MQ(LG) {
    margin-bottom:2em !important;
  }
  padding: 19px;
  margin: auto !important;
  margin-top: 1.25em !important;
  border-radius: 2rem;
  background: #faf8f7;
}

.form-title {
  display: flex;
  justify-content:space-between;
}

.invitationForm {
  padding:0.75em;
  background: #fff;
}

#copy{
  display: flex;
  justify-content:space-between;
  align-items:center;
  border: 1px solid #d9d9d9;
  border-radius: 20px;
  padding:5px;
}

#board{
  width:500px;
  height: 5vh;
  border: 0 none;
  font-size: medium;
}

#copyButton {
  margin-left: 20px;
  color:#fff;
  border-radius: 2rem;
  font-size: 0.8rem;
}

.divider{
  margin-top: 20px;
  margin-bottom: 20px;
  border-top: 2px solid rgba(0,0,0,0.1);
  display: flex;
  justify-self: space-between;
}

.snackbar {
  border: radius 5rem;
}
