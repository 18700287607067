.firstLoginWarning {
  visibility: visible;
  background-color: #fffae5;
  border: 1px solid #ffeb99;
  font-weight: bold;
  color: red;
  text-align: center;
  padding: 10px;
  margin: 2px;
}
.firstLoginWarningContent {
  font-size: 8px;
}
