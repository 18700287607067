.icon-basic {
  width: 29.844px;
  height: 29.844px;
  border-radius: 19.5px;
  border:1px #d9d9d9 solid;
  text-align: center;
  margin-top: 5px;
  margin-right: 5px;
  top: 0;
  right: 0;
  position: absolute;
}
.more {
  @extend .icon-basic;
  z-index: 1;
  &_hide {
    visibility: hidden;
  }
}
.clear {
  @extend .icon-basic;
  color: #f2eae4;
  z-index: 3;
  &_hide {
    visibility: hidden;
  }
}
.menuItems {
  background-color: #1b6ac9;
  color: #f2eae4;
  width: 260px;
  margin: 0;
  list-style: none;
  padding-top: 40px;
  padding-left: 0;
  border-bottom-left-radius: 5px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 2;
  transition: 300ms;
  &_hidden {
    @extend .menuItems;
    right: -260px;
  }
}
.menuItem {
  font-size:16px;
  float:left;
  display: flex;
  border-bottom: #081fbe solid 1px;
  padding: {
    left: 5px;
    top: 5px;
    bottom: 5px;
  }
  width: 100%;
  &:last-child {
    border-bottom-left-radius: 5px;
  }
  &:hover {
    background-color: #081fbe;
    transition: background-color 500ms;
  }
  &_title {
    padding-left: 5px;
  }
}
.menuItem_disabled {
  color: rgb(179, 179, 179);
  font-size:16px;
  float:left;
  display: flex;
  background-color: rgb(224, 224, 224);
  padding: {
    left: 5px;
    top: 5px;
    bottom: 5px;
  }
  width: 100%;
  &:last-child {
    border-bottom-left-radius: 5px;
  }
  &_title {
    padding-left: 5px;
  }
}

.sideBarIcon {
  font-size: 20px;
}
