$MD: 768px;

@mixin MQ($canvas) {
  @if $canvas==MD {
    @media screen and (max-width: $MD - 16) {
      @content;
    }
  }
}

.cardWrapper {
  @include MQ(MD) {
    margin-left: 5%;
	}
  width: 50%;
  box-sizing: border-box;
  padding-bottom: 30px;
  display: flex;
}
.icon{
  margin-right: 15px;
}
.name {
  font-weight: bold;
  font-size: 15px;
}

.companyName {
  font-size: 13px;
}

.email {
  font-size: 13px;
}
