@import "src/pages/components/CompanyPanel/components/EditCompany/components/CompanyProfile/responsive";


.goBack {
  text-align: center;
  display: block;
  height: auto;
  margin: auto;
  padding-bottom: 14px;
  padding-top: 14px;
  padding: 1rem;
  box-sizing: border-box;
  max-width: 48em;
  background: #fff;
  .icon {
    margin: auto;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 3px;
    .square {
      width: 16px;
      height: 16px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-content: space-between;
      & > div {
        width: 5px;
        height: 5px;
        border: solid 1px #cecece;
        margin: 0.2px;
        border-radius: 1px;
      }
    }
    .name {
      margin-left: 8px;
      margin-bottom: 3px;
      text-decoration: underline;
      color: dodgerblue;
      font-weight: 700;
    }
  }
}

.goBack:hover {
  cursor: pointer;
}

.companyPanel {
  @include MQ(MD) {
    margin: 0 auto 10rem;
  }

  @include MQ(LG) {
    margin: auto;
    border-radius: 5px;
  }
  top: -8px;
  padding: 1rem;
  box-sizing: border-box;
  max-width: 53em;
  min-height: 100vh;
  background: #fff;
  overflow: hidden;
  position: relative;
}
