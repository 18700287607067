@import 'src/pages/components/CompanyPanel/components/EditCompany/components/CompanyProfile/_responsive';

.goBack{
  @include MQ(MD) {
    width: 100%;
  }
  @include MQ(LG) {
    width: 87%;
  }
  text-align: center;
  display: block;
  height: auto;
  margin: auto;
  padding-bottom: 14px;
  padding-top: 14px;
  box-sizing: border-box;
  max-width: 48em;
  background: #fff;

  .icon{
    margin: auto;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-bottom: 3px;
    .square{
      width: 16px;
      height: 16px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      align-content: space-between;
      & > div {
        width: 5px;
        height: 5px;
        border: solid 1px #cecece;
        margin: 0.2px;
        border-radius: 1px;
      }
    }
  }
}

.goBack:hover{
  cursor:pointer;
}

.mainPaper{
  top: 11px;
  padding: 2rem;
  box-sizing: border-box;
  max-width: 53em;
  min-height: 100vh;
  background: #fff;
  display: block;
  margin: 0 auto;

  @include MQ(MD) {
    border-top-left-radius: 0;
    border-top-right-radius: 0 ;
  }
  @include MQ(LG) {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px ;
  }
}
