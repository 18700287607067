body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f2f7;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

#root{
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

@font-face {
  font-family: "Poppins-Regular";
  src: local("Poppins-Regular"),
   url("../src/assets/fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Bold";
  src: local("Poppins-Bold"),
   url("../src/assets/fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: local("Poppins-SemiBold"),
   url("../src/assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Medium";
  src: local("Poppins-Medium"),
   url("../src/assets/fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}
