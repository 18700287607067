$MD: 768px;

@mixin MQ($canvas) {
  @if $canvas==MD {
    @media screen and (max-width: $MD) {
      @content;
    }
  }
  @else if $canvas==LG {
    @media screen and (min-width: $MD) {
      @content;
    }
  }
}

.companyPanel{
  @include MQ(MD) {
    margin: 0 auto 10rem;
  }

  @include MQ(LG) {
    margin: auto;
  }
  top: 11px;
  padding: 2rem;
  box-sizing: border-box;
  max-width: 53em;
  min-height:100vh;
  background: #fff;
  overflow: hidden;
  position: relative;
}

.name {
  font-size: xx-large;
  text-align: center;
  font-weight: bold;
}
.description {
  box-sizing: border-box;
  margin: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0 50px;
  text-align: center;
}
