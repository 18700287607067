$MD: 768px;

@mixin MQ($canvas) {
  @if $canvas==MD {
    @media screen and (max-width: $MD) {
      @content;
    }
  }
  @else if $canvas==LG {
    @media screen and (min-width: $MD) {
      @content;
    }
  }
}

.wrapper {
  @include MQ(MD) {
    text-align: center;
  }
  @include MQ(LG) {
    border-bottom: 3px solid black;
  }
  padding: 10px 0;

  border-bottom: 3px solid black;
  @include MQ(MD) {
    border-bottom: 0;
	}
}

.peopleList {
  padding: 5px;
}

.bottomContent {
  padding: 30px 50px;
  display: flex;
  flex-wrap: wrap;
}

.button {
  @include MQ(MD) {
    margin: 15px auto;
	}
  width: 120px;
  z-index: 1;
}
